import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';

export default function DataNav() {
  const data = useStaticQuery(graphql`
    query listDataMenuLinks {
      allMenuItems(
        sort: { weight: ASC }
        filter: {
          menu_name: { eq: "data" }
          id: { glob: "menu-items-menu_link_content:*" }
        }
      ) {
        nodes {
          title
          url
        }
      }
    }
  `);

  return (
    <nav className="vl-u-spacer-bottom--medium">
      <h5 className="vl-title vl-title--h5">Data</h5>
      <ul className="vl-link-list vl-link-list--small">
        {data.allMenuItems.nodes.map((link, idx) => (
          <li key={idx}>
            <Link className="vl-link vl-link--bold" to={link.url}>{link.title}</Link>
          </li>
        ))}
      </ul>
    </nav>
  );
}
