import React from 'react';
import { graphql, useStaticQuery, navigate } from 'gatsby';

export default function HeaderSearchForm(): React.JSX.Element {
  const data = useStaticQuery(graphql`
    query getSearchPage {
      configPagesPageSettings {
        relationships {
          field_search_page {
            path {
              alias
            }
          }
        }
      }
    }
  `);

  const searchPagePath =
    data.configPagesPageSettings.relationships.field_search_page.path.alias;

  const handleSubmit = (event) => {
    event.preventDefault();
    const searchParam = event.target.elements.q.value;
    navigate(`${searchPagePath}?q=${searchParam}`);
    // Empty input field
    event.target.elements.q.value = '';
  };

  return searchPagePath ? (
    <form onSubmit={handleSubmit} noValidate>
      <div className="vl-search vl-search--inline">
        <label className="vl-search__label" htmlFor="q">
          <span className="vl-u-visually-hidden">Zoekterm</span>
        </label>
        <div className="vl-search__input-wrapper">
          <input
            className="vl-input-field vl-search__input"
            type="search"
            name="vl-search"
            id="q"
            title="Zoekterm"
            placeholder="Zoeken"
          />
          <button
            className="vl-search__submit vl-button vl-button--icon vl-button--naked-action"
            type="submit"
          >
            <span
              aria-hidden="true"
              className="vl-button__icon vl-icon vl-vi vl-vi-magnifier"
            ></span>
            <span className="vl-button__label">
              <div className="vl-u-visually-hidden">Zoeken</div>
            </span>
          </button>
          <button
            className="vl-search__reset vl-button vl-button--icon vl-button--naked-action"
            type="reset"
          >
            <span aria-hidden="true" className="vl-button__icon"></span>
            <span className="vl-button__label vl-u-visually-hidden"></span>
          </button>
        </div>
      </div>
    </form>
  ) : (
    <></>
  );
}
