import React, {createContext, useContext, useState} from 'react';
import {isBrowser} from '../utils/utils';

interface INavigationContext {
  currentTab: string;
  setCurrentTab: React.Dispatch<React.SetStateAction<string>>;
}

const NavigationContext = createContext<INavigationContext | null>(null);

export const NavigationProvider = ({children}) => {
  if (isBrowser()) {
    const [currentTab, setCurrentTab] = useState<string>(
      window.location.pathname,
    );

    const api = {currentTab, setCurrentTab};
    return (
      <NavigationContext.Provider value={api}>
        {children}
      </NavigationContext.Provider>
    );
  } else {
    return null;
  }
};

export const useNavigation = () => useContext(NavigationContext);
