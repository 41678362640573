import React from 'react';

import './src/sass/_govflanders.scss';
import { DataProvider } from './src/contexts';
import { QueryClient, QueryClientProvider } from 'react-query';
import { NavigationProvider } from './src/contexts/useNavigation';
import Layout from './src/components/Layout/Layout';
import { BrowserRouter as Router } from 'react-router-dom';

import './src/sass/index.scss';
import './src/sass/tooltip.scss';
import './src/sass/waves.scss';
import './src/sass/navigation.scss';
import './src/sass/loader.scss';
import './src/sass/table.scss';
import { Helmet } from 'react-helmet';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { Location, globalHistory } from '@reach/router';

import '@govflanders/vl-ui-core';
import '@govflanders/vl-ui-accordion';
import '@govflanders/vl-ui-modal';
import '@govflanders/vl-ui-select';
import '@govflanders/vl-ui-form-validation';

const queryClient = new QueryClient();

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: 'nl',
        }}
      >
        <link
          key="gatsby-plugin-plausible-preconnect"
          rel="preconnect"
          href="https://plausible.lblod.info"
        ></link>
        <script
          async
          defer
          data-domain="gemeente-stadsmonitor.vlaanderen.be"
          src="https://plausible.lblod.info/js/script.file-downloads.js"
        ></script>
      </Helmet>
      <Router>
        <NavigationProvider>
          <Location>
            {({ location }) => (
              <QueryParamProvider
                location={location}
                reachHistory={globalHistory}
                adapter={ReactRouter6Adapter}
              >
                <QueryClientProvider client={queryClient}>
                  <DataProvider>
                    <Layout>{element}</Layout>
                  </DataProvider>
                </QueryClientProvider>
              </QueryParamProvider>
            )}
          </Location>
        </NavigationProvider>
      </Router>
    </>
  );
};

export const onRouteUpdate = () => {
  document.dispatchEvent(new Event('DOMContentLoaded'));
};
