import React from 'react';
import DataNav from './DataNav';
import FooterNav from './FooterNav';
import FaqNav from './FaqNav';
import waves from '../../../static/waves/footer.svg';

export default function MainFooter(): React.JSX.Element {
  return (
    <footer className="vl-content-footer">
      <div
        className="vl-content-footer__wrapper"
        style={{ backgroundImage: `url(${waves})` }}
      >
        <div className="vl-layout">
          <div className="vl-grid vl-grid--is-stacked">
            <div className="vl-col--1-3 vl-col--1-1--s">
              <DataNav />
            </div>
            <div className="vl-col--1-3 vl-col--1-1--s">
              <FooterNav />
            </div>
            <div className="vl-col--1-3 vl-col--1-1--s">
              <FaqNav />
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
