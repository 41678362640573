import * as d3 from 'd3';
import React from 'react';
import { createContext, useContext, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { graphql, useStaticQuery } from 'gatsby';

interface IDataContext {
  percentFormatter: any;
  percentNoCommaFormatter: any;
  singleBarChartProps: any;
  setSingleBarChartProps: any;
  selectedIndicators: any;
  setSelectedIndicators: any;
  selectedGemeente: any;
  setSelectedGemeente: any;
  selectedBenchmarks: any;
  setSelectedBenchmarks: any;
  colors: any;
  setColors: any;
  selectedIndicator: any;
  setSelectedIndicator: any;
  dataGemeente: any;
  setDataGemeente: any;
  minMaxIndicatorData: any;
  setMinMaxIndicatorData: any;
  dataBarchartDashboard: any;
  setDataBarchartDashboard: any;
  tableWidth: any;
  setTableWidth: any;
  indicatorLabel: any;
  setIndicatorLabel: any;
  valueFormatter3: any;
  // columnVisibilityModel: any;
  // setColumnVisibilityModel: any;
  // getVisibilityModel: any;
  radarIdToHighlight: any;
  setRadarIdToHighlight: any;
  selectedTopic: any;
  setSelectedTopic: any;
  setTopics: any;
  searchParams: any;
  setSearchParams: any;
  highlightedGemeenteId: any;
  setHighlightedGemeenteId: any;
  blobUrl: any;
  selectedIndicatorData: any;
  setSelectedIndicatorData: any;
  blobUrlSamenvattingen: any;
  blobUrlClusters: any;
  blobUrlDashboard: any;
  blobUrlGeo: any;
  locale: any;
  isDesktop: any;
  isTablet: any;
  isMobile: any;
  benchColors: any;
  blobUrlSpreiding: any;
  gemeenteColor: any;
  vgColor: any;
  interfaceColor: any;
  barColors: any;
  radarColors: any;
  vregColors: any;
  clusterColors: any;
  blobUrlClustersIndicatoren: any;
  clusterColorsInteractieve: any;
  infoData: any;
  setInfoData: any;
  infoDataDetailView: any;
  setInfoDataDetailView: any;
  themesListGlobal: any;
  setThemesListGlobal: any;
  indicatorsListGlobal: any;
  setIndicatorsListGlobal: any;
  blobUrlBron: any;
  globalSourceFilter: any;
  setGlobalSourceFilter: any;
  showTable: any;
  setShowTable: any;
  indexColors: any;
  deltaColors: any;
  isZoomedInsights: any;
  setIsZoomedInsights: any;
  zoomFilterIds: any;
  setZoomFilterIds: any;
  zoomFilters: any;
  setZoomFilters: any;
  blobUrlRankings: any;
  selectedIndicatorsInzichten: any;
  setSelectedIndicatorsInzichten: any;
  fillColorHighlight: any;
}

const DataContext = createContext<IDataContext | null>(null);

export const DataProvider = ({ children }) => {
  const { site } = useStaticQuery(graphql`
    query siteMetadata {
      site {
        siteMetadata {
          envs {
            BLOB_STORAGE_BASE_URL
          }
        }
      }
    }
  `);

  const fillColorHighlight = '#5B1D7A';

  const isDesktop = useMediaQuery({ minWidth: 1366 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 1365 });
  const isMobile = useMediaQuery({ maxWidth: 767 });

  // move to separate ts file
  const percentFormatter = d3.format(',.1%');
  const percentNoCommaFormatter = d3.format(',.0%');
  const valueFormatter3 = d3.format(',.3s');
  const colors = [
    '#24779B',
    '#ff595e',
    '#c8b6ff',
    '#595959',
    '#fcbf49',
    '#1491BB',
    '#ED7D31',
    '#70AD47',
    '#ddccFF',
    '#FC6C76',
    '#9999FF',
    '#98B585',
    '#88CCEE',
    '#1491BB',
    '#ff595e',
    '#5B9BD5',
    '#ED7D31',
    '#70AD47',
    '#FFC000',
    '#eeccee',
    '#FFC000',
    '#ff595e',
  ];

  /// Temp colors for interactieve tool
  const gemeenteColor = '#D53E5E';
  const benchColors = [
    '#2B92BE',
    '#E69B1F',
    '#2D7D47',
    '#9A50BE',
    '#15465B',
    '#D26E25',
    '#4BCFA5',
    '#86263B',
    '#F0D70F',
    '#5B1D7A',
    '#443939',
    '#FFED00',
  ];

  /// Colors requested by client

  const interfaceColor = '#1C7074';
  const barColors = [
    '#D53E5E',
    '#86263B',
    '#15465B',
    '#2B92BE',
    '#4BCFA5',
    '#2D7D47',
    '#5B1D7A',
    '#9A50BE',
    '#E69B1F',
    '#D26E25',
    '#F0D70F',
    '#FFED00',
    '#443939',
  ];
  const radarColors = ['#86263B', '#bbb', '#E69B1F'];
  const indexColors = ['#5B1D7A', '#bbb', '#E69B1F'];
  const vregColors = ['#D53E5E', '#2B92BE', '#D26E25']; // vogelvlucht register
  const clusterColors = ['#1C7074', '#D53E5E', '#2B92BE', '#CC6677'];
  const clusterColorsInteractieve = [
    '#B9DFF0',
    '#61B7DC',
    '#288DB9',
    '#1A5671',
    '#05151D',
    '#D26E25',
  ];

  const vgColor = '#D26E25'; // Vlaams gewest
  const deltaColors = ['#2B92BE', '#D26E25'];
  /////////////////////

  const locale = d3.formatLocale({
    decimal: ',',
    thousands: '.',
    grouping: [3],
    currency: ['€\u00a0', ''],
  });

  const blobUrl = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Overzicht_Json/`;
  const blobUrlSamenvattingen = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Samenvatting_Json/`;
  const blobUrlClusters = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Clusters_Json/`;
  const blobUrlDashboard = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Dashboard_Json/`;
  const blobUrlGeo = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/`;
  const blobUrlSpreiding = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Spreiding_Json/`;
  const blobUrlClustersIndicatoren = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Clusters_Indicatoren_Json/`;
  const blobUrlBron = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}brondata/indicator/`;
  const blobUrlRankings = `${site.siteMetadata.envs.BLOB_STORAGE_BASE_URL}newgsm-json/Extracten_Rankings_Json/`;
  // till here

  const [tableWidth, setTableWidth] = useState(6);
  const [dataBarchartDashboard, setDataBarchartDashboard] = useState([]);
  const [singleBarChartProps, setSingleBarChartProps] = useState([
    {
      id: 1,
      fieldName: 'teller',
      fill: '#4281a4',
      type: 'bar',
      maxBarSize: 2,
    },
  ]);

  const [radarIdToHighlight, setRadarIdToHighlight] = useState();

  const [themesListGlobal, setThemesListGlobal] = useState([]);

  const [indicatorsListGlobal, setIndicatorsListGlobal] = useState([]);

  const [selectedIndicators, setSelectedIndicators] = useState([
    // 100, 1072, 2450, 7, 1046, 2, 8, 10, 9, 3, 6, 4, 5, 11, 1, 12, 14, 13, 1047,
    // 15,
  ]);

  const [selectedIndicatorsInzichten, setSelectedIndicatorsInzichten] =
    useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedIndicatorData, setSelectedIndicatorData] = useState({
    id: -1,
    indicatorLabel: '',
  });
  const [indicatorLabel, setIndicatorLabel] = useState('');

  const [selectedGemeente, setSelectedGemeente] = useState({
    id: -1,
    label: '',
  });

  const [selectedBenchmarks, setSelectedBenchmarks] = useState([
    {
      id: 11002,
      idRuimte: 11002,
      label: 'Vlaams Gewest',
      ruimte: 'Vlaams Gewest',
    },

    // {
    //   id: 11002,
    //   idRuimte: 11002,
    //   ruimte: 'Antwerpen',
    //   typeRuimte: 'Gemeente',
    //   idRuimteProvincie: 10000,
    //   idRuimteRegio: 991007,
    //   idRuimteBuurgemeenten: 9811002,
    //   idRuimteGemeenteGrootte: 200005,
    //   idRuimteBelfiusCluster: 221015,
    //   isGemeente: 1,
    //   isVlaamseGemeente: 1,
    //   isVlaamsGewest: 0,
    //   isBrusselsGewest: 0,
    //   is13CentrumSteden: 0,
    //   isFaciliteitenGemeente: 0,
    //   isNietFaciliteitenGemeente: 0,
    //   isVlaamseRand: 0,
    //   isProvincie: 0,
    //   isRegio: 0,
    //   inVlaamseRand: 0,
    //   in13CentrumSteden: 1,
    //   bench: 0,
    //   label: 'Antwerpen',
    // },
  ]);

  const [highlightedGemeenteId, setHighlightedGemeenteId] = useState();

  // const getVisibilityModel = () => {
  //   let tmp = {};
  //   if (selectedGemeente.id !== -1) {
  //     tmp[selectedGemeente.label] = false;
  //     selectedBenchmarks.forEach((d: any) => {
  //       tmp[d.label] = false;
  //     });
  //   }
  //   return tmp;
  // };

  // const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(
  //   getVisibilityModel(),
  // );

  const [dataGemeente, setDataGemeente] = useState([]);

  const [selectedTopic, setSelectedTopic] = useState({
    id: 0,
    thema: 'Synthese',
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const [minMaxIndicatorData, setMinMaxIndicatorData] = useState([
    {
      idIndicator: 290,
      valueMax: 0.05,
      valueMin: 0,
    },
    {
      idIndicator: 294,
      valueMax: 8.3,
      valueMin: 0,
    },
    {
      idIndicator: 298,
      valueMax: 0.4,
      valueMin: 0,
    },
    {
      idIndicator: 304,
      valueMax: 0.05,
      valueMin: 0,
    },
    {
      idIndicator: 1,
      valueMax: 160,
      valueMin: 40,
    },
  ]);

  const [infoData, setInfoData] = useState([]);
  const [infoDataDetailView, setInfoDataDetailView] = useState([]);

  const [globalSourceFilter, setGlobalSourceFilter] = useState([
    { name: 'Survey', checked: true },
    { name: 'Register', checked: true },
  ]);

  const [showTable, setShowTable] = useState(true);

  const [isZoomedInsights, setIsZoomedInsights] = useState(false);
  const [zoomFilters, setZoomFilters] = useState({});
  const [zoomFilterIds, setZoomFilterIds] = useState([]);

  const api = {
    globalSourceFilter,
    setGlobalSourceFilter,
    percentFormatter,
    percentNoCommaFormatter,
    singleBarChartProps,
    setSingleBarChartProps,
    selectedIndicators,
    setSelectedIndicators,
    selectedGemeente,
    setSelectedGemeente,
    selectedBenchmarks,
    setSelectedBenchmarks,
    colors,
    selectedIndicator,
    setSelectedIndicator,
    dataGemeente,
    setDataGemeente,
    minMaxIndicatorData,
    setMinMaxIndicatorData,
    dataBarchartDashboard,
    setDataBarchartDashboard,
    tableWidth,
    setTableWidth,
    indicatorLabel,
    setIndicatorLabel,
    valueFormatter3,
    // columnVisibilityModel,
    // setColumnVisibilityModel,
    // getVisibilityModel,
    radarIdToHighlight,
    setRadarIdToHighlight,
    selectedTopic,
    setSelectedTopic,
    searchParams,
    setSearchParams,
    highlightedGemeenteId,
    setHighlightedGemeenteId,
    blobUrl,
    selectedIndicatorData,
    setSelectedIndicatorData,
    blobUrlSamenvattingen,
    blobUrlClusters,
    blobUrlDashboard,
    blobUrlGeo,
    locale,
    isDesktop,
    isTablet,
    isMobile,
    benchColors,
    blobUrlSpreiding,
    gemeenteColor,
    vgColor,
    interfaceColor,
    radarColors,
    barColors,
    vregColors,
    clusterColors,
    blobUrlClustersIndicatoren,
    clusterColorsInteractieve,
    infoData,
    setInfoData,
    infoDataDetailView,
    setInfoDataDetailView,
    themesListGlobal,
    setThemesListGlobal,
    indicatorsListGlobal,
    setIndicatorsListGlobal,
    blobUrlBron,
    showTable,
    setShowTable,
    indexColors,
    deltaColors,
    isZoomedInsights,
    setIsZoomedInsights,
    zoomFilterIds,
    setZoomFilterIds,
    zoomFilters,
    setZoomFilters,
    blobUrlRankings,
    selectedIndicatorsInzichten,
    setSelectedIndicatorsInzichten,
    fillColorHighlight,
  };
  return <DataContext.Provider value={api}>{children}</DataContext.Provider>;
};

export const useData = () => useContext(DataContext);
