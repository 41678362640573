import React from 'react';
import FlemishHeader from '../FlemishHeader/FlemishHeader';
import MainNavigation from '../MainNavigation/MainNavigation';
import MainFooter from '../MainFooter/MainFooter';
import FlemishFooter from '../FlemishFooter/FlemishFooter';
export default function Layout({ children }): React.JSX.Element {
  return (
    <>
      <FlemishHeader />
      <MainNavigation />
      <main>
        {children}

        <div className="loader-overlay hidden">
          <div className="vl-loader"/>
        </div>
      </main>
      <MainFooter />
      <FlemishFooter />
    </>
  );
}
