import React from 'react';
import {graphql, Link, useStaticQuery} from 'gatsby';

export default function DataNav() {
    const data = useStaticQuery(graphql`
    query getFAQPage {
      configPagesPageSettings {
        relationships {
          field_faq_page {
            path {
              alias
            }
          }
        }
      }
    }
  `);

    return (
      <aside className="footer__extra">
        <div className="vl-u-spacer-bottom--medium">
          <h5 className="vl-title vl-title--h5">Heb je een vraag?</h5>
            <p style={{marginBottom: '1rem'}}>Neem een kijkje bij de{' '}
              <Link
                to={data.configPagesPageSettings.relationships.field_faq_page.path.alias}
                className="vl-link vl-link--bold"
              >veelgestelde vragen</Link>
            </p>
        </div>

        <div>
          <h5 className="vl-title vl-title--h5">Of contacteer ons</h5>
          <a href="mailto:gemeente-stadsmonitor@vlaanderen.be" className="vl-link vl-link--bold">
            <span
              className="vl-link__icon vl-link__icon--before vl-icon--large vl-vi vl-vi-envelope"
              aria-hidden="true"
            />gemeente-stadsmonitor@vlaanderen.be
          </a>
        </div>
      </aside>
    );
}
